import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';
import { BrowserQRCodeReader, BrowserCodeReader, IScannerControls } from '@zxing/browser';
import { NotFoundException, ChecksumException, FormatException } from '@zxing/library';
import { Modal } from 'bootstrap';
import { Nullable } from '../interfaces/nullable.type';

// Connects to data-controller="application"
export default class extends Controller {
  static targets = ['device', 'scanForm', 'base64Code', 'sourceWrapper', 'video', 'spinner', 'codeError', 'modal'];
  codeReader: BrowserQRCodeReader;
  inputDevices: Nullable<MediaDeviceInfo[]>;
  selectedDeviceId: string;
  readerControls: IScannerControls;
  deviceSelectElement: Nullable<HTMLElement>;
  modal: Modal;
  initResult: boolean;
  codeErrorTimeoutId: Nullable<number>;
  sourceWrapperTarget: HTMLElement;
  videoTarget: HTMLVideoElement;
  spinnerTarget: HTMLElement;
  base64CodeTarget: HTMLInputElement;
  scanFormTarget: HTMLFormElement;
  codeErrorTarget: HTMLElement;
  modalTarget: HTMLElement;

  openScanModal() {
    this.modal = new bootstrap.Modal(this.modalTarget, {
      backdrop: true,
      keyboard: true,
      focus: true,
    });

    this.modalTarget.addEventListener('hide.bs.modal', () => {
      if (this.readerControls != null) {
        this.readerControls.stop();
      }

      // Reset code after animation is complete
      setTimeout(() => this.resetDecoderError(), 1000);
    });

    this.modal.show();

    this.startReading();
  }

  deviceTargetConnected(element: HTMLElement) {
    this.deviceSelectElement = element;
  }

  onDeviceChange(event: Event) {
    this.selectedDeviceId = (event.target as HTMLInputElement)?.value;
    if (this.readerControls != null) {
      this.readerControls.stop();
    }
    this.startReading();
  }

  async startReading() {
    this.initResult = await this.init();

    if (!this.initResult || this.codeReader == null || this.selectedDeviceId == null) {
      console.error('Code reader initialization failed!');
      return;
    }
    const element = document.getElementById('scan_video') as HTMLVideoElement | null;
    if (element == null) {
      return;
    }
    this.readerControls = await this.codeReader.decodeFromVideoDevice(this.selectedDeviceId, element, (result, error, controls) => {
      if (result != null) {
        const text = result.getText().replace('https://','').replace('staging.','').replace('certifiedtransaction.io/scan/validate?code=','');
        const format = result.getBarcodeFormat();
        const numBits = result.getNumBits();
        if (text != null && !!text.length && format === 11 && numBits > 900) {
          try {
            const value = JSON.parse(atob(text));
            if (value != null && value.mid != null) {
              controls.stop();

              this.resetDecoderError();
              this.sourceWrapperTarget?.classList.add('hidden');
              this.videoTarget.classList.add('hidden');
              this.spinnerTarget.classList.remove('hidden');

              this.base64CodeTarget.value = text;
              this.scanFormTarget.submit();
            } else {
              this.setDecoderError('Not a CTS code. This may be a fraudulent code.', true);
              this.readerControls.stop();
            }
          } catch (e) {
            console.error('Error decoding QR result');
            this.setDecoderError('Not a CTS code. This may be a fraudulent code.', true);
            this.readerControls.stop();
          }
        } else {
          console.warn('Invalid format!');
          this.setDecoderError('Invalid format');
        }
      }

      if (error != null) {
        if (error instanceof NotFoundException) {
          // Uncomment for debugging.
          // console.info('No QR code found.');
        }

        if (error instanceof ChecksumException) {
          console.warn('A code was found, but it\'s read value was not valid.');
          this.setDecoderError();
        }

        if (error instanceof FormatException) {
          console.warn('A code was found, but it was in a invalid format.');
          this.setDecoderError();
        }
      }
    });
  }

  async init() {
    if (this.initResult) {
      return true;
    }
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      await navigator.mediaDevices.getUserMedia({ video: true });
    }

    this.codeReader = new BrowserQRCodeReader();

    try {
      this.inputDevices = await BrowserCodeReader.listVideoInputDevices();
      if (this.inputDevices.length < 1) {
        console.error('No video input devices!');
      } else {
        if (this.inputDevices.length === 2) {
          this.selectedDeviceId = this.inputDevices[1].deviceId;
        } else {
          this.selectedDeviceId = this.inputDevices[0].deviceId;
        }
        if (this.inputDevices.length > 1) {
          this.sourceWrapperTarget.classList.remove('hidden');
        } else {
          this.sourceWrapperTarget.classList.add('hidden');
        }
      }
      if (this.deviceSelectElement != null) {
        this.deviceSelectElement.querySelectorAll('option').forEach((option: HTMLOptionElement) => option.remove());
        this.inputDevices.forEach((id: MediaDeviceInfo) => {
          const option = document.createElement('option');
          option.value = id.deviceId;
          option.textContent = id.label;
          this.deviceSelectElement?.appendChild(option);
        });
      }
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }

  setDecoderError(errorMessage?: string, persist?: boolean) {
    this.videoTarget.classList.add('code-error');
    this.codeErrorTarget.classList.remove('hidden');
    this.codeErrorTarget.innerText = errorMessage || 'Not a valid code';

    if (this.codeErrorTimeoutId != null) {
      clearTimeout(this.codeErrorTimeoutId);
    }

    if (persist) {
      this.videoTarget.classList.add('hidden');
      this.sourceWrapperTarget.classList.add('hidden');
      return;
    }

    this.codeErrorTimeoutId = setTimeout(() => {
      this.codeErrorTimeoutId = undefined;
      this.videoTarget.classList.remove('code-error');
      this.codeErrorTarget.classList.add('hidden');
    }, 3000);
  }

  resetDecoderError() {
    this.videoTarget.classList.remove('hidden');
    this.sourceWrapperTarget.classList.remove('hidden');
    this.videoTarget.classList.remove('code-error');
    this.codeErrorTarget.classList.add('hidden');
    this.codeErrorTarget.innerText = '';
  }
}
