import { Nullable } from '../interfaces/nullable.type';

export class MapService {
  static getBoundsFromMarkers(markers: google.maps.Marker[], lat?: Nullable<number>, lng?: Nullable<number>): google.maps.LatLngBounds {
    const latlngbounds = new google.maps.LatLngBounds();

    if (lat != null && lng != null) {
      latlngbounds.extend(new google.maps.LatLng(lat, lng));
    }
    markers.forEach((marker: google.maps.Marker) => {
      const markerPosition = marker.getPosition();
      if (markerPosition == null) {
        return;
      }
      latlngbounds.extend(markerPosition);
    });
    return latlngbounds;
  }
}