import { Application } from '@hotwired/stimulus';
import Pagy from 'pagy-module';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

window.addEventListener('turbo:load', Pagy.init);

export { application };
