import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';
import { Collapse } from 'bootstrap';

// Connects to data-controller="jurisdictions"
export default class extends Controller {
  collapseMap: Record<string, Collapse> = {};

  connect() {
    document.querySelectorAll('.jurisdiction-group.collapse').forEach(el => {
      this.collapseMap[el.id] = new bootstrap.Collapse(el, { toggle: false });
    });
  }

  toggleGroup(event: any) {
    const collapseId = 'collapse' + event.params.id;
    const isShown = document.getElementById(collapseId)?.classList.contains('show');

    this.collapseMap[collapseId].toggle();

    const disclosureIcon = document.getElementById('disclosure-icon-' + event.params.id);

    if (isShown) {
      disclosureIcon?.classList.add('closed');
    } else {
      disclosureIcon?.classList.remove('closed');
    }
  }
}
