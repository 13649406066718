import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="merchant-edit"
export default class extends Controller {
  static targets = ['map', 'addressField', 'cityField', 'postalCodeField', 'countyField', 'stateField', 'latField', 'lngField'];
  autocomplete: google.maps.places.Autocomplete;
  mapTarget: HTMLElement;
  addressFieldTarget: HTMLInputElement;
  cityFieldTarget: HTMLInputElement;
  postalCodeFieldTarget: HTMLInputElement;
  stateFieldTarget: HTMLInputElement;
  countyFieldTarget: HTMLInputElement;
  latFieldTarget: HTMLInputElement;
  lngFieldTarget: HTMLInputElement;
  map: google.maps.Map;
  marker: google.maps.Marker;
  hasInitializedMap = false;

  connect() {
    if (window.google != null) {
      this.initMap();
    }
  }

  initMap() {
    if (this.hasInitializedMap) {
      return;
    }
    this.hasInitializedMap = true;

    const latValue = this.data.get('lat');
    const lngValue = this.data.get('lng');

    const lat = parseFloat(latValue || '39.5');
    const lng = parseFloat(lngValue || '-98.35');

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(lat, lng),
      zoom: !latValue || !lngValue ? 4 : 17,
    });
    this.autocomplete = new google.maps.places.Autocomplete(this.addressFieldTarget, {
      componentRestrictions: {
        country: 'us'
      }
    });
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    if (lat != null && lng != null && !isNaN(lat) && !isNaN(lng)) {
      this.marker.setPosition(new google.maps.LatLng(lat, lng));
      this.marker.setVisible(true);
    }
  }

  placeChanged() {
    const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
    if (place == null || place.geometry == null) {
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else if (place.geometry.location != null) {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    this.marker.setPosition(place.geometry.location);
    this.marker.setVisible(true);

    this.latFieldTarget.value = place.geometry.location?.lat().toString() ?? '';
    this.lngFieldTarget.value = place.geometry.location?.lng().toString() ?? '';

    this.addressFieldTarget.value = this.getAddressFromComponents(place.address_components);

    const city = place.address_components?.find(c => c.types.includes('locality'));
    if (city != null) {
      this.cityFieldTarget.value = city.long_name;
    }
    const postalCode = place.address_components?.find(c => c.types.includes('postal_code'));
    if (postalCode != null) {
      this.postalCodeFieldTarget.value = postalCode.short_name;
    }
    const county = place.address_components?.find(c => c.types.includes('administrative_area_level_2'));
    if (county != null) {
      this.countyFieldTarget.value = county.long_name;
    }
    const state = place.address_components?.find(c => c.types.includes('administrative_area_level_1'));
    if (state != null) {
      this.stateFieldTarget.value = state.short_name;
    }
  }

  getAddressFromComponents(components: google.maps.GeocoderAddressComponent[] = []) {
    const allowedTypes = ['street_number', 'route', 'subpremise'];
    const allowedComponents = components.filter(c => c.types.some(t => allowedTypes.includes(t)));

    const streetNumber = allowedComponents.find(c => c.types.includes('street_number'));
    const streetName = allowedComponents.find(c => c.types.includes('route'));
    const subpremise = allowedComponents.find(c => c.types.includes('subpremise'));

    let address = streetNumber == null ? '' : `${streetNumber.long_name} `;
    if (streetName != null) {
      address += `${streetName.short_name}`;
    }
    if (subpremise != null) {
      address += `, ${subpremise.short_name}`;
    }

    return address.trim();
  }
}
