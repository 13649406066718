import { Controller } from '@hotwired/stimulus';
import { filter, Subject, takeUntil } from 'rxjs';
import { Merchant } from '../interfaces/merchant.interface';
import { MapService } from '../services/map.service';

// Connects to data-controller="area_search"
export default class extends Controller {
  static targets = ['map', 'error', 'mapButton'];
  map: google.maps.Map;
  markers: google.maps.Marker[] = [];
  mapTarget: HTMLElement;
  mapButtonTarget: HTMLButtonElement;
  hasMapInitialized = false;
  private unsubscribe$ = new Subject();

  connect() {
    if (window.google != null) {
      this.initMap();
    }
    window.cts.services.merchants.isMapOpen$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((isMapOpen) => this.mapToggle(isMapOpen));

    window.cts.services.merchants.gridData$.pipe(
      filter(() => this.hasMapInitialized),
      takeUntil(this.unsubscribe$),
    ).subscribe((gridData) => this.addMarkers(gridData));
  }

  disconnect() {
    super.disconnect();
    this.unsubscribe$.next(null);
  }

  initMap() {
    if (this.hasMapInitialized) {
      return;
    }
    this.hasMapInitialized = true;
    console.info('Init Map');
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(39.5, -98.35),
      zoom: 4
    });
    this.addMarkers(window.cts.services.merchants.gridData);
  }

  addMarkers(gridData: Merchant[]) {
    if (!gridData.length) {
      this.clearMarkers();
      return;
    }

    gridData.forEach((item: Merchant) => {
      const lat = parseFloat(item.lat);
      const lng = parseFloat(item.lng);
      if (lat == null || lng == null || isNaN(lat) || isNaN(lng)) {
        return;
      }

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: this.map,
        title: item.name,
      });

      let infowindow: google.maps.InfoWindow | undefined;

      google.maps.event.addListener(marker, 'click', () => {
        infowindow = infowindow ?? new google.maps.InfoWindow({
          content: window.cts.services.merchants.getInfowindowContent(item),
        });
        infowindow.open({ anchor: marker, map: this.map });
      });
      this.markers.push(marker);
    });
    this.autoFitMarkers();
  }

  clearMarkers() {
    this.markers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  autoFitMarkers() {
    if (!this.markers.length) {
      return;
    }
    this.map.fitBounds(MapService.getBoundsFromMarkers(this.markers));
    setTimeout(() => {
      const zoom = this.map.getZoom();
      if (zoom != null && zoom > 14) {
        this.map.setZoom(14);
      }
    }, 1); // Wait for fitBounds to be applied before checking zoom level.
  }

  onShowMapClick(): void {
    window.cts.services.merchants.toggleMapOpen();
  }

  mapToggle(show: boolean) {
    if (show) {
      this.mapTarget.classList.remove('hidden');
      this.mapButtonTarget.classList.remove('btn-outline-secondary');
      this.mapButtonTarget.classList.add('btn-secondary');
      this.autoFitMarkers();
    } else {
      this.mapTarget.classList.add('hidden');
      this.mapButtonTarget.classList.remove('btn-secondary');
      this.mapButtonTarget.classList.add('btn-outline-secondary');
    }
  }
}
