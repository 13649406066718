import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="merchant"
export default class extends Controller {
  static targets = ['map'];
  mapTarget: HTMLElement;
  map: google.maps.Map;
  marker: google.maps.Marker;
  hasInitializedMap = false;

  connect() {
    if (window.google != null) {
      this.initMap();
    } else {
      window.addEventListener('google-maps-callback', () => this.initMap());
    }
  }

  initMap() {
    if (this.hasInitializedMap) {
      return;
    }
    this.hasInitializedMap = true;

    const latValue = this.data.get('lat');
    const lngValue = this.data.get('lng');

    const lat = parseFloat(latValue || '39.5');
    const lng = parseFloat(lngValue || '-98.35');

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(lat, lng),
      zoom: !latValue || !lngValue ? 4 : 17,
    });

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    if (lat != null && lng != null && !isNaN(lat) && !isNaN(lng)) {
      this.marker.setPosition(new google.maps.LatLng(lat, lng));
      this.marker.setVisible(true);
    }
  }
}
